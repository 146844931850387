import React from 'react'
import GroupChip from './GroupChip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
  },
  listWrapper: {
    overflowX: 'auto',
    display: 'flex',
    paddingLeft: '1rem',
  },
  actionContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  actionButton: {
    marginRight: '0.5rem',
    marginBottom: '0.5rem',
  },
  border: {
    border: 'none',
    borderTop: 'solid 1px',
    margin: 0,
    marginBottom: '0.5rem',
    padding: 0,
    textAlign: 'center',
  },
  empty: {
    textAlign: 'center',
    width: '100%',
  },
}))

function GroupList({ groups, onLeaveGroup, setQuery }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <fieldset className={classes.border}>
        <legend>Grupper</legend>
      </fieldset>
      <div className={classes.listWrapper}>
        {groups?.map((el) => (
          <GroupChip
            key={el.id}
            group={el}
            onLeave={onLeaveGroup}
            setQuery={setQuery}
          />
        ))}
        <div className={classes.empty}>
          {groups?.length <= 0 && 'Du har ingen grupper'}
        </div>
      </div>
    </div>
  )
}

export default GroupList
