import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Schedule from '../../../components/Schedule'
import Loader from '../../../components/Loader'
import Button from '@material-ui/core/Button'
import AddSchedule from '../../../components/AddForm'
import JoinGroup from './JoinGroup'
import AddGroup from './AddGroup'

const useStyles = makeStyles(() => ({
  horizontalList: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    msOverflowX: 'auto',
    // background: 'linear-gradient(to right,white, #a4ebf3)',
  },
  empty: {
    width: '100%',
    textAlign: 'center',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: '0.5rem 0',
  },
  actionButton: {
    marginRight: '0.5rem',
    marginBottom: '0.5rem',
    width: '190px',
    background: '#55b368',
    '&:hover': {
      background: '#5aa469',
    },
  },

  border: {
    border: 'none',
    borderTop: 'solid 1px',
    margin: '0.5rem 0',
    padding: 0,
    textAlign: 'center',
  },
}))

function HorizontalList(props) {
  const classes = useStyles()
  const [showScheduleForm, setShowScheduleForm] = useState(false)
  const [showGroupForm, setShowGroupForm] = useState(false)
  const [showJoinForm, setShowJoinForm] = useState(false)
  return (
    <section className={classes.root}>
      <fieldset className={classes.border}>
        <legend>Dine møter</legend>
      </fieldset>
      <div className={classes.horizontalList}>
        <Loader loading={props.loading} error={props.error}>
          {props.data?.mySchedules?.schedules
            ?.filter(
              (el) => el.startAt + 3 * 60 * 60 * 100 > new Date().getTime()
            )
            .sort((a, b) => a.startAt - b.startAt)
            .map((el) => (
              <Schedule key={el.id + 1} schedule={el} onLeave={props.onLeave} />
            ))}
          {props.data?.mySchedules?.schedules?.length <= 0 && (
            <div className={classes.empty}>Du har ingen kommende møter</div>
          )}
        </Loader>
      </div>

      <div className={classes.actionContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={() => setShowJoinForm(true)}
        >
          Velg gruppe
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.actionButton}
          onClick={() => setShowGroupForm(true)}
        >
          Oprett gruppe
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowScheduleForm(true)}
          className={classes.actionButton}
        >
          Opprett nytt møte
        </Button>
        <AddSchedule
          onCreate={props.onCreateSchedule}
          open={showScheduleForm}
          setOpen={setShowScheduleForm}
          groups={props.groups}
        />
        <JoinGroup
          onJoin={props.onJoinGroup}
          open={showJoinForm}
          setOpen={setShowJoinForm}
        />
        <AddGroup
          onCreate={props.onCreateGroup}
          open={showGroupForm}
          setOpen={setShowGroupForm}
        />
      </div>
    </section>
  )
}

export default HorizontalList
