import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
function PwdField({
  showPwd,
  setShowPwd,
  onChange,
  value,
  name,
  label,
  onInvalid,
  className,
  labelWidth,
  onInput,
  rmPath,
}) {
  return (
    <FormControl variant="outlined" className={className} fullWidth required>
      <InputLabel htmlFor="filled-adornment-password">{label}</InputLabel>
      <OutlinedInput
        id={'filled-adornment-' + name}
        type={showPwd ? 'text' : 'password'}
        name={name}
        onInvalid={onInvalid}
        value={value}
        autoComplete={'off'}
        required
        onChange={onChange}
        onInput={onInput}
        inputProps={{
          pattern: rmPath ? null : '(?=.*[a-z])(?=.*[A-Z]).{6,}',
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={setShowPwd}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              {showPwd ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={labelWidth}
      />
    </FormControl>
  )
}

export default PwdField
