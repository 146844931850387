import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import avatar from '../../assets/avatar.png'
import PwdField from './PwdField'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    backgroundColor: 'white',
    padding: '1rem',
    position: 'relative',
    borderRadius: '5px',
    margin: '1rem',
    maxWidth: '450px',
  },
  avatarContainer: {
    textAlign: 'center',
  },
  avatar: {
    width: '40%',
    borderRadius: '50%',
  },
  input: { marginTop: '1.5rem' },
  submit: {
    backgroundColor: '#fc7e28',
    marginTop: '1rem',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e56d1b',
    },
  },
  cancel: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
    fontSize: 'xx-large',
  },
}))
function Login(props) {
  const classes = useStyles()
  const [userInput, setUserInput] = useState({ email: '', password: '' })
  const [helpText, setHelpText] = useState('')
  const [showPwd, setShowPwd] = useState(false)

  const inputHandler = (e) => {
    setUserInput((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onCloseHandler = () => {
    setUserInput({ email: '', password: '' })
    props.onClose()
  }

  const submitHandler = async (e) => {
    setHelpText(null)
    e.preventDefault()
    const res = await props.onSubmit(userInput)
    if (res) {
      setHelpText(res)
    }
  }

  useEffect(() => {
    return () => {
      setUserInput({ email: '', password: '' })
      setHelpText(null)
    }
  }, [])

  const invalidInputHandler = (e) => {
    switch (e.target.name) {
      case 'email':
        e.target.setCustomValidity('Ugyldig epostadresse')
        break
      case 'password':
        e.target.setCustomValidity('Ugyldig passord')
        break
      default:
        break
    }
  }
  const onInputHandler = (e) => {
    e.target.setCustomValidity('')
  }

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={onCloseHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <form className={classes.form} onSubmit={submitHandler}>
          <Button
            title="close modal"
            className={classes.cancel}
            onClick={onCloseHandler}
          >
            ⨉
          </Button>
          <div className={classes.avatarContainer}>
            <img src={avatar} alt="Avatar" className={classes.avatar} />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              label="email"
              name="email"
              onInvalid={invalidInputHandler}
              onInput={onInputHandler}
              type="email"
              variant="outlined"
              required
              fullWidth
              value={userInput.email}
              onChange={inputHandler}
              className={classes.input}
            />
            <PwdField
              label="Passord"
              name="password"
              onInvalid={invalidInputHandler}
              onInput={onInputHandler}
              value={userInput.password}
              onChange={inputHandler}
              className={classes.input}
              setShowPwd={() => setShowPwd((prev) => !prev)}
              showPwd={showPwd}
              labelWidth={65}
              rmPath={true}
            />
            {helpText && (
              <Typography
                variant="body2"
                color="error"
                className={classes.helpText}
              >
                {helpText}
              </Typography>
            )}
          </div>
          <Button
            variant="contained"
            fullWidth
            className={classes.submit}
            type="submit"
          >
            Logg inn
          </Button>
        </form>
      </Fade>
    </Modal>
  )
}

export default Login
