import { withStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Switch from '@material-ui/core/Switch'
const PurpleSwitch = withStyles({
  switchBase: {
    color: 'whitesmoke',
    '& + $track': {
      backgroundColor: 'lightgray',
    },
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

export default PurpleSwitch
