// Month (0-11) 0-January
// Date (1-31)
// Weekday (0-6) 0-Sunday
// Hour (0-23)
// Minute (0-59)

const MONTH = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
]

export const getDateJump = (date, inc = 1) => {
  const year = date.getFullYear()
  const month = date.getMonth()
  let newMonth = month + inc
  let newYear = year
  if (newMonth > 11) {
    newYear += 1
    newMonth = 0
  } else if (newMonth < 0) {
    newMonth = 11
    newYear -= 1
  }
  const daysInMonth = new Date(newYear, newMonth + 1, 0).getDate()
  return {
    year: newYear,
    month: newMonth,
    start: Date.parse(new Date(newYear, newMonth, 1, 0, 0, 0, 0)),
    end: Date.parse(new Date(newYear, newMonth, daysInMonth, 23, 59, 59, 999)),
    date: new Date(newYear, newMonth, 7),
  }
}

export const getDateInfo = (date = new Date()) => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const data = {
    year,
    month,
    monthText: MONTH[month],
    weekDay: date.getDay(),
    daysInMonth: new Date(year, month + 1, 0).getDate(),
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
  }
  return data
}
