import React, { useState, Fragment } from 'react'
import AddForm from '../../../components/AddForm'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Schedule from '../../../components/Schedule'

const useStyles = makeStyles(() => ({
  paper: { margin: 0, width: '100%' },
  dialog: { margin: '10px', minWidth: 300 },
  content: { margin: 0, padding: 10 },
}))
function DaySchedules({
  schedules,
  open,
  setOpen,
  onJoin,
  onLeave,
  date,
  onCreate,
  groups,
}) {
  const [showAddForm, setShowAddForm] = useState(false)
  const classes = useStyles()

  return (
    <Fragment>
      <AddForm
        open={showAddForm}
        setOpen={setShowAddForm}
        onCreate={onCreate}
        defaultValue={date}
        onClose={() => setOpen(false)}
        groups={groups}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="paper"
        open={open}
        classes={{ paper: classes.paper }}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
      >
        <DialogTitle id="form-dialog-title">
          {date?.toLocaleDateString()}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {schedules?.map((el) => (
            <Schedule
              schedule={el}
              key={el.id}
              vertical={true}
              onJoin={onJoin}
              onLeave={onLeave}
            />
          ))}
          {schedules?.length <= 0 && 'Ingen møter er tilgjengelige'}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddForm(true)} color="primary">
            Opprett Nytt Møte
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default DaySchedules
