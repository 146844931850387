import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
const useStyle = makeStyles((theme) => ({
  root: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
  btn: {
    margin: '0 0.5rem',
    textDecoration: 'none',
    boxShadow: 'none',
    color: 'white',
    backgroundColor: '#e56d1b',
    '&:hover': {
      backgroundColor: '#ac5519',
      boxShadow: 'none',
    },
    border: 'none',
  },
  dropdown: {
    position: 'relative',
    zIndex: '1000',
  },
  dropdownContent: {
    position: 'absolute',
    backgroundColor: 'white',
    right: '10px',
  },
}))

function MobileNav({ state, onLogin, onLogout, onSignup }) {
  const [outerMenu, setOuterMenu] = useState(false)
  const [innerMenu, setInnerMenu] = useState(false)
  const classes = useStyle()

  return (
    <nav className={classes.root}>
      {state?.user?.id ? (
        <Fragment>
          {innerMenu ? (
            <ClickAwayListener onClickAway={() => setInnerMenu(false)}>
              <div className={classes.dropdown}>
                {'Hei ' + state.user.firstName + ','}

                <IconButton onClick={() => setInnerMenu((prev) => !prev)}>
                  <AccountCircleIcon />
                </IconButton>
                <div className={classes.dropdownContent}>
                  <MenuItem onClick={onLogout}>Logg ut</MenuItem>
                </div>
              </div>
            </ClickAwayListener>
          ) : (
            <div className={classes.dropdown}>
              {'Hei ' + state.user.firstName + ','}
              <IconButton onClick={() => setInnerMenu((prev) => !prev)}>
                <AccountCircleIcon />
              </IconButton>
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {outerMenu ? (
            <ClickAwayListener onClickAway={() => setOuterMenu(false)}>
              <div className={classes.dropdown}>
                <IconButton onClick={() => setOuterMenu((prev) => !prev)}>
                  <MenuIcon />
                </IconButton>
                <div className={classes.dropdownContent}>
                  <MenuItem onClick={onSignup}>Ny profil</MenuItem>
                  <MenuItem onClick={onLogin}>Logg inn</MenuItem>
                </div>
              </div>
            </ClickAwayListener>
          ) : (
            <div className={classes.dropdown}>
              <IconButton onClick={() => setOuterMenu((prev) => !prev)}>
                <MenuIcon />
              </IconButton>
            </div>
          )}
        </Fragment>
      )}
    </nav>
  )
}

export default MobileNav
