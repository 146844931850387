//https://www.apollographql.com/docs/link/links/context/
//https://www.apollographql.com/docs/react/caching/cache-configuration/
//https://www.apollographql.com/docs/tutorial/client/

import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'

const setAuthorizationLink = setContext((_, { headers }) => {
  const localToken = JSON.parse(window.localStorage.getItem('token'))
  const token = localToken ? localToken : null
  return {
    headers: { ...headers, authorization: token ? 'Bearer ' + token : null },
  }
})
const link = createHttpLink({ uri: process.env.REACT_APP_API })
const cache = new InMemoryCache()
cache.writeData({ data: { token: window.localStorage.getItem('token') } })

const client = new ApolloClient({
  cache,
  link: setAuthorizationLink.concat(link),
})

export default client
