import makeContext from './makeContext'
const token = window.localStorage.getItem('token')
const INITIAL_STATE = {
  user: null,
  token: token ? token : null,
}

const initializeUser = (dispatch) => (user) => {
  dispatch({ type: 'initialize_user', payload: user })
}

const login = (dispatch) => (auth) => {
  dispatch({ type: 'login', payload: auth })
}

const logout = (dispatch) => () => {
  dispatch({ type: 'logout', payload: null })
}

const userReducer = (state, action) => {
  switch (action.type) {
    case 'initialize_user':
      return { ...state, ...action.payload }
    case 'add_log':
      state.logs.push(action.payload)
      return { ...state }
    case 'login':
      window.localStorage.setItem('token', JSON.stringify(action.payload.token))
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      }
    case 'logout':
      window.localStorage.removeItem('token')
      return { ...state, user: null, token: null }
    default:
      return { ...state }
  }
}

export const { Context, Provider } = makeContext(
  userReducer,
  {
    login,
    logout,
    initializeUser,
  },
  INITIAL_STATE
)
