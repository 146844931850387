import React, { Fragment, useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import Popper from '@material-ui/core/Popper'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { makeStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'
import { Context as AuthContext } from '../../../context/Auth'
// import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles(() => ({
  chip: {
    marginRight: '0.5rem',
    padding: '0.5rem',
    color: 'black',
    textTransform: 'uppercase',
    height: '40px',
    // background: '#86a19a',
    background: 'white',
    border: 'solid 1px',
    borderColor: '#3c415c',
    '&:hover': {
      // background: '#728c86',
      background: 'whitesmoke',
    },
  },
  actionButton: {
    marginTop: '0.5rem',
    background: 'white',
    '&:hover': { background: 'whitesmoke' },
  },
  settingIcon: {
    marginLeft: '5px',
    color: 'gray',
  },
}))

function GroupChip({ group, history, onLeave, setQuery }) {
  const { state } = useContext(AuthContext)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const updateQuery = () => {
    if (group?.id) {
      setQuery((prev) => ({ ...prev, groupId: group.id }))
      setAnchorEl(null)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'clickaway-popper' : undefined
  const classes = useStyles()
  const onGroupEdit = () => {
    history.push('/groups/' + group.id)
  }

  const handleLeave = () => {
    if (group?.id) {
      onLeave(group.id)
    }
  }
  return (
    <Fragment>
      {group?.owner?.id === state?.user?.id ? (
        <Chip
          className={classes.chip}
          label={group?.name}
          onClick={handleClick}
          // avatar={<Avatar>{group?.name.slice(0, 1)}</Avatar>}
          onDelete={onGroupEdit}
          deleteIcon={<SettingsIcon className={classes.settingIcon} />}
        />
      ) : (
        <Chip
          className={classes.chip}
          label={group?.name}
          onClick={handleClick}
          // avatar={<Avatar>{group?.name.slice(0, 1)}</Avatar>}
        />
      )}
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.actionButton}
              onClick={updateQuery}
            >
              Vis Møtene
            </Button>
            <br />
            {group?.owner?.id !== state?.user?.id && (
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleLeave}
                className={classes.actionButton}
              >
                Forlat
              </Button>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Fragment>
  )
}

export default withRouter(GroupChip)
