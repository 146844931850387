import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CSwitch from './CSwitch'
import Chip from '@material-ui/core/Chip'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import useDebounce from '../../hooks/useDebounce'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
// import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    textAlign: 'center',
  },
  sectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: ' space-around',
  },
  sectionBase: {
    width: '45%',
    minWidth: '310px',
    display: 'flex',
    background: '#3c415c',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '0 1rem 1rem 1rem',
    borderRadius: '5px',
    margin: '5px',
    color: 'white',
  },
  membersHeader: {
    margin: 0,
    paddingTop: '7px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  groupIdContainer: {
    display: 'flex',
    paddingTop: '10px',
  },
  groupDescription: {
    marginTop: '10px',
    background: 'white',
    borderRadius: '3px',
  },
  switchContainer: {
    width: '98%',
  },
  resetButton: {
    marginLeft: '1rem',
    minWidth: '36%',
    color: 'white',
    borderColor: 'white',
  },
  groupId: {
    width: '60%',
    background: 'white',
    borderRadius: '3px',
  },
  groupTitle: {
    background: 'white',
    borderRadius: '3px',
  },
  chip: {
    marginBottom: '7px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  settingIcon: {
    color: 'lightgray',
  },
}))

function GroupForm({
  group,
  onReset,
  onUpdate,
  onExclude,
  onResetUser,
  onDelete,
}) {
  const classes = useStyles()
  const [onEdit, setOnEdit] = useState(false)
  const [onAdvance, setOnAdvance] = useState(false)
  const [onMembers, setOnMembers] = useState(true)
  const [groupInput, setGroupInput] = useState(
    group ? group : { name: '', identifier: '', open: false, description: '' }
  )

  const { debounceValue } = useDebounce(groupInput, 2000)

  useEffect(() => {
    if (group?.id) {
      setGroupInput(group)
    }
  }, [group])

  useEffect(() => {
    if (onEdit && group?.id) {
      const input = {
        name: groupInput.name,
        description: groupInput.description,
      }
      onUpdate({ variables: { groupId: group.id, input } })
      setOnEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue])

  const handleChange = (e) => {
    setOnEdit(true)
    setGroupInput((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleCheck = (e) => {
    if (group.id) {
      onUpdate({
        variables: { groupId: group.id, input: { open: e.target.checked } },
      })
    }
  }

  const handleExclude = (userId) => {
    if (group.id) {
      onExclude({ variables: { groupId: group.id, userId } })
    }
  }

  const handleResetUser = (userId) => {
    if (group.id) {
      onResetUser({ variables: { groupId: group.id, userId } })
    }
  }

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Gruppeinformasjon</h2>
      <div className={classes.sectionContainer}>
        <div className={classes.sectionBase}>
          <h3 className={classes.membersHeader}>
            <span>Info</span>
            {onAdvance ? (
              <Tooltip title="Avanserte innstillinger">
                <IconButton onClick={() => setOnAdvance(false)}>
                  <SettingsIcon className={classes.settingIcon} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Skjul avanserte innstillinger">
                <IconButton onClick={() => setOnAdvance(true)}>
                  <SettingsIcon className={classes.settingIcon} />
                </IconButton>
              </Tooltip>
            )}
          </h3>
          <TextField
            label="Gruppenavn"
            size="small"
            name="name"
            color="primary"
            className={classes.groupTitle}
            variant="filled"
            value={groupInput?.name}
            onChange={handleChange}
          />
          <TextField
            label="Beskrivelse"
            size="small"
            name="description"
            multiline={true}
            rows={2}
            color="primary"
            className={classes.groupDescription}
            variant="filled"
            value={groupInput?.description}
            onChange={handleChange}
          />
          <div className={classes.groupIdContainer}>
            <TextField
              label="GrupppeId"
              fullWidth
              variant="filled"
              size="small"
              value={groupInput?.identifier}
              onChange={() => null}
              className={classes.groupId}
            />
            <Button
              variant="outlined"
              className={classes.resetButton}
              onClick={onReset}
            >
              regenerer
            </Button>
          </div>
          <FormControlLabel
            className={classes.switchContainer}
            value="start"
            labelPlacement="start"
            control={
              <CSwitch
                checked={groupInput.open}
                onChange={handleCheck}
                name="checkedB"
                color="primary"
              />
            }
            label="Åpen for nye medlemmer"
          />
          {onAdvance && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => onDelete && onDelete(group?.id)}
            >
              Slett gruppen
            </Button>
          )}
        </div>
        <div className={classes.sectionBase}>
          <h3 className={classes.membersHeader}>
            <span>{onMembers ? 'Gruppemedlemmer' : 'Blokkerte bruker'}</span>
            {onMembers ? (
              <Tooltip title="Vis blokkerte bruker">
                <IconButton onClick={() => setOnMembers(false)}>
                  <SettingsIcon className={classes.settingIcon} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Vis gruppemedlemmer">
                <IconButton onClick={() => setOnMembers(true)}>
                  <SettingsIcon className={classes.settingIcon} />
                </IconButton>
              </Tooltip>
            )}
          </h3>
          {onMembers
            ? groupInput?.members?.map((el) => {
                if (!el?.id) {
                  return null
                }
                if (el.id === group?.owner?.id) {
                  return (
                    <Chip
                      label={`${el?.firstName} ${el?.lastName} ${
                        el.id === group?.owner?.id ? '(admin)' : ''
                      }`}
                      key={el.id}
                      className={classes.chip}
                    />
                  )
                } else {
                  return (
                    <Chip
                      label={`${el?.firstName} ${el?.lastName}`}
                      key={el.id}
                      className={classes.chip}
                      onDelete={() => handleExclude(el.id)}
                      deleteIcon={
                        <Tooltip title="Blokker bruker">
                          <RemoveCircleIcon />
                        </Tooltip>
                      }
                    />
                  )
                }
              })
            : groupInput?.excluded?.map((el) => (
                <Chip
                  label={`${el?.firstName} ${el?.lastName}`}
                  key={el.id}
                  className={classes.chip}
                  onDelete={() => handleResetUser(el.id)}
                  deleteIcon={
                    <Tooltip title="Reset bruker">
                      <RotateLeftIcon />
                    </Tooltip>
                  }
                />
              ))}
        </div>
      </div>
    </div>
  )
}

export default GroupForm
