import React, { useReducer } from "react";

const makeContext = (reducer, actions, initialState) => {
  const Context = React.createContext();
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const dispatchActions = {};

    for (let key in actions) {
      dispatchActions[key] = actions[key](dispatch);
    }
    return (
      <Context.Provider value={{ state, ...dispatchActions }}>
        {children}
      </Context.Provider>
    );
  };
  return { Context, Provider };
};

export default makeContext