import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  addButton: {
    width: '100%',
    marginBottom: '1rem',
    display: 'block',
  },
  dialog: { margin: '10px', minWidth: 300 },
  paper: { margin: 0, width: '100%' },
  helpText: {
    color: 'red',
  },
}))

const validate = (input, c) => {
  if (input.title === '') {
    c('Tittelen kan ikke være blank')
    return false
  } else if (input.description === '') {
    c('Beskrivelsen kan ikke være blank')
    return false
  }
  return true
}

export default function AddForm({ open, setOpen, onCreate, onClose }) {
  const [input, setInput] = useState({ name: '', description: '' })
  const [helpText, setHelpText] = useState('')
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }
  const inputHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSubmitHandler = async () => {
    const valid = validate(input, setHelpText)
    if (valid) {
      const res = await onCreate(input)
      if (res) {
        setHelpText('')
        setOpen(false)
        onClose && onClose()
      } else {
        setHelpText('')
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="form-dialog-title">Opprett nytt møte</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          autoComplete="off"
          id="name"
          label="Gruppenavn"
          name="name"
          type="text"
          fullWidth
          value={input.name}
          onChange={inputHandler}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="name"
          multiline
          rows={4}
          rowsMax={4}
          label="Beskrivelse"
          name="description"
          type="text"
          value={input.description}
          onChange={inputHandler}
          fullWidth
        />
        {helpText && <div className={classes.helpText}>{helpText}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Avbryt
        </Button>
        <Button onClick={onSubmitHandler} color="primary">
          Opprett
        </Button>
      </DialogActions>
    </Dialog>
  )
}
