import React, { useContext, useState } from 'react'
import { Context as AuthContext } from '../context/Auth'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { formatDistanceStrict } from 'date-fns'
import { nb } from 'date-fns/locale'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '91px',
    minHeight: '91px',
    position: 'relative',
    width: '100%',
    minWidth: '330px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '280px',
    },
  },
  vertical: {
    marginTop: '0.5rem',
  },
  horizontal: {
    margin: '0.5rem',
    color: 'black',
    maxWidth: '380px',
  },

  frontTitle: {
    padding: 0,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  info: {
    padding: 0,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  defaultInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1rem',
    overflow: 'hidden',
  },
  actionOverlay: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    background: '#f1efef',
    width: '100%',
    bottom: 0,
    left: 0,
    top: 0,
    right: 0,
    borderRadius: 0,
    padding: ' 1rem',
    boxSizing: 'border-box',
    overflow: 'hidden',
    transition: '.5s ease',
    zIndex: '100',
  },
  detailOverlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    bottom: 0,
    left: 0,
    top: 0,
    right: 0,
    background: '#f1efef',
    borderRadius: '0 0 0 0',
    padding: ' 1rem',
    boxSizing: 'border-box',
    overflowY: 'auto',
    overflow: 'hidden',
    zIndex: '100',
    transition: '.5s ease',
  },
  noSpacing: {
    margin: 0,
    padding: 0,
  },
  joinButton: {
    background: '#96bb7c',
    '&:hover': {
      background: '#83a26d',
    },
  },
  leaveButton: {
    marginTop: '0.5rem',
    background: '#cc561e',
    color: 'white',
  },
  infoButton: {
    background: '#f2edd7',
    color: 'gray',
    '&:hover': {
      background: '#dcd7bd',
    },
  },
  actionContainer: {
    height: '100%',
    color: 'white',
    minWidth: '95px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingRight: '5px',
  },
}))

function Schedule({ schedule, vertical = false, history, onJoin, onLeave }) {
  const classes = useStyles()
  const { state } = useContext(AuthContext)
  const [onAction, setOnAction] = useState(false)
  const [onInfo, setOnInfo] = useState(false)
  const handleMeet = () => {
    const start = new Date(schedule?.startAt)?.getTime()
    const current = new Date().getTime()

    if (current > start - 60 * 60 * 1000) {
      history.push('/video-conference/' + schedule.roomId)
    } else {
      setOnAction(true)
    }
  }

  const isMember = schedule?.members?.find(
    (el) => state?.user?.id && el?.id && el?.id === state?.user?.id
  )

  return (
    <Paper
      elevation={2}
      className={clsx(
        classes.root,
        vertical && classes.vertical,
        !vertical && classes.horizontal
      )}
    >
      <div className={classes.defaultInfo}>
        <h3 className={classes.frontTitle}>{schedule.title}</h3>
        <p className={classes.info}>
          {'Tema: ' + schedule.topics.join(', ')} <br />
          {new Date(schedule.startAt).toLocaleString()}
        </p>
      </div>

      {onInfo && (
        <ClickAwayListener onClickAway={() => setOnInfo(false)}>
          <div className={classes.detailOverlay}>
            <h3 className={classes.noSpacing}>{schedule.title}</h3>
            <p className={classes.noSpacing}>
              {new Date(schedule.startAt).toLocaleString()}
              <br />
              {`Deltager: ${schedule?.members?.length}`}
              <br />
              {'Tema: ' + schedule.topics.join(', ')}
              <br />

              {'Beskrivelse: ' + schedule.description}
              <br />
            </p>

            {isMember && (
              <Button
                variant="contained"
                className={classes.leaveButton}
                onClick={() => onLeave && onLeave(schedule?.id)}
              >
                Forlat
              </Button>
            )}
          </div>
        </ClickAwayListener>
      )}

      {onAction && (
        <ClickAwayListener onClickAway={() => setOnAction(false)}>
          <div className={classes.actionOverlay}>
            <p>Deltagere: {schedule?.members?.length}</p>
            <p>
              Starter om{' '}
              {formatDistanceStrict(schedule.startAt, new Date(), {
                locale: nb,
                addSuffix: false,
              })}
            </p>
          </div>
        </ClickAwayListener>
      )}
      <div className={classes.actionContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.infoButton}
          onClick={() => setOnInfo(true)}
        >
          Info
        </Button>
        {isMember ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.joinButton}
            onClick={handleMeet}
          >
            Møt
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.joinButton}
            onClick={() => onJoin && onJoin(schedule.id)}
          >
            Bli med
          </Button>
        )}
      </div>
    </Paper>
  )
}

export default withRouter(Schedule)
