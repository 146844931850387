import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
  addButton: {
    width: '100%',
    marginBottom: '1rem',
    display: 'block',
  },
  dialog: { margin: '10px', minWidth: 300 },
  paper: { margin: 0, width: '100%' },
  helpText: {
    color: 'red',
  },
}))

export default function JoinGroup({ open, setOpen, onJoin, onClose }) {
  const [input, setInput] = useState('')
  const [helpText, setHelpText] = useState('')
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }
  const inputHandler = (e) => {
    setInput(e.target.value)
  }

  const onSubmitHandler = async () => {
    const res = await onJoin(input)
    if (res) {
    } else {
      setHelpText('')
    }
    setHelpText('')
    setInput('')
    setOpen(false)
    onClose && onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="form-dialog-title">
        Bruk grupppeID for å bli med
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          autoComplete="off"
          id="groupid"
          label="GruppeID"
          name="groupid"
          type="text"
          fullWidth
          value={input}
          onChange={inputHandler}
        />
        {helpText && <div className={classes.helpText}>{helpText}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Avbryt
        </Button>
        <Button onClick={onSubmitHandler} color="primary">
          Bli med
        </Button>
      </DialogActions>
    </Dialog>
  )
}
