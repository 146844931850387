import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import Chip from '@material-ui/core/Chip'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  addButton: {
    width: '100%',
    marginBottom: '1rem',
    display: 'block',
  },
  dialog: { margin: '10px', minWidth: 300 },
  paper: { margin: 0, width: '100%' },
  helpText: {
    color: 'red',
  },
  topicContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addTopic: { marginLeft: ' 0.5rem', marginTop: '3px', whiteSpace: 'nowrap' },
  addTopicText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  addTopicSign: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
    },
  },

  topicWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const validate = (input, c) => {
  if (input.title === '') {
    c('Tittelen kan ikke være blank')
    return false
  } else if (input.description === '') {
    c('Beskrivelsen kan ikke være blank')
    return false
  } else if (input.topics.length < 1) {
    c('Temaene kan ikke være blank')
    return false
  } else if (
    input.startAt === 'Invalid Date' ||
    input.startAt.getTime() <= new Date().getTime()
  ) {
    return false
  }
  return true
}

export default function AddForm({
  open,
  setOpen,
  onCreate,
  defaultValue,
  defaultGroup,
  onClose,
  groups,
}) {
  const [topic, setTopic] = useState('')
  const [input, setInput] = useState({
    title: '',
    topics: [],
    description: '',
    groupId: defaultGroup ? defaultGroup : '',
    startAt: defaultValue
      ? defaultValue
      : new Date(new Date().getTime() + 1 * 60 * 1000),
  })
  const [helpText, setHelpText] = useState('')
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }
  const inputHandler = (e) => {
    if (e instanceof Date) {
      setInput((prev) => ({ ...prev, startAt: e }))
    } else if (e?.target?.name === 'topic') {
      setTopic(e.target.value)
    } else {
      setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }
  }

  const onSubmitHandler = async () => {
    const valid = validate(input, setHelpText)
    if (valid) {
      await onCreate(input)
      setInput({
        title: '',
        topics: [],
        description: '',
        groupId: '',
        startAt: defaultValue
          ? defaultValue
          : new Date(new Date().getTime() + 1 * 60 * 1000),
      })
      setHelpText('')
      setOpen(false)
      onClose && onClose()
    }
  }

  const addTopicHandler = () => {
    if (!input.topics.includes(topic) && topic !== '') {
      const updatedTopics = [...input.topics, topic]
      setInput((prev) => ({ ...prev, topics: updatedTopics }))
      setTopic('')
    }
  }
  const removeTopicHandler = (topic) => {
    const updatedTopics = input.topics.filter((el) => el !== topic)
    setInput((prev) => ({ ...prev, topics: updatedTopics }))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="form-dialog-title">Opprett nytt møte</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          autoFocus
          variant="outlined"
          margin="dense"
          autoComplete="off"
          id="name"
          label="Tittel"
          name="title"
          type="text"
          fullWidth
          value={input.title}
          onChange={inputHandler}
        />
        <div className={classes.topicContainer}>
          <TextField
            margin="dense"
            variant="outlined"
            autoComplete="off"
            id="topic"
            name="topic"
            label="Tema"
            type="text"
            fullWidth
            value={topic}
            onChange={inputHandler}
          />
          <Button
            color="primary"
            variant="outlined"
            className={clsx(classes.addTopic, topic && 'scale')}
            onClick={addTopicHandler}
          >
            <span className={classes.addTopicText}>Legg til</span>
            <span className={classes.addTopicSign}>+</span>
          </Button>
        </div>
        <div className={classes.topicWrapper}>
          {input.topics.map((el, index) => (
            <Chip
              key={el + index}
              label={el}
              onDelete={() => removeTopicHandler(el)}
            />
          ))}
        </div>

        <Grid container justify="space-around">
          <KeyboardDateTimePicker
            margin="normal"
            fullWidth
            inputVariant="outlined"
            size="small"
            id="date-picker-dialog"
            disablePast={true}
            label="Velg tidspunkt"
            name="from"
            minDate={new Date()}
            maxDate={new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000)}
            format="dd.MM.yyyy hh:mm a"
            cancelLabel="Avbryt"
            value={input.startAt}
            onChange={inputHandler}
            minDateMessage="Dato kan ikke være tidligere i tid"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <TextField
          variant="outlined"
          margin="dense"
          id="name"
          multiline
          rows={4}
          rowsMax={4}
          label="Beskrivelse"
          name="description"
          type="text"
          value={input.description}
          onChange={inputHandler}
          fullWidth
        />
        <FormControl
          variant="outlined"
          className={classes.formControl}
          size="small"
          fullWidth
        >
          <InputLabel id="demo-simple-select-outlined-label">Gruppe</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="groupId"
            value={input.groupId}
            onChange={inputHandler}
            disabled={!!defaultGroup}
            label="Gruppe"
          >
            <MenuItem value="">
              <em>Åpen møter</em>
            </MenuItem>
            {groups?.map((el) => (
              <MenuItem value={el.id} key={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {helpText && <div className={classes.helpText}>{helpText}</div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Avbryt
        </Button>
        <Button onClick={onSubmitHandler} color="primary">
          Opprett
        </Button>
      </DialogActions>
    </Dialog>
  )
}
