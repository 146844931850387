import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    height: '1.5rem',
    bottom: 0,
    width: '100%',
  },
})

function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Typography variant="body1">
        © Wemeet {new Date().getFullYear()}{' '}
      </Typography>
    </footer>
  )
}

export default Footer
