import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getDateInfo, getDateJump } from '../../../utils/date'
import Day from './Day'
const useStyles = makeStyles((theme) => ({
  calendarContainer: {
    width: '62%',
    paddingLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 5px',
    },
  },
  calendarHeader: {
    padding: '10px 25px',
    background: '#dcd7bd',
    textAlign: 'center',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  weekdays: {
    margin: 0,
    padding: '10px 0',
    '& li': {
      display: 'inline-block',
      width: '11.5%',
      margin: '0 1%',
      border: 'solid 1px transparent',
      color: '#666',
      textAlign: 'center',
    },
  },
  days: {
    padding: 0,
    margin: 0,
    '& li': {
      listStyleType: 'none',
      display: 'inline-block',
      width: '11.5%',
      height: '60px',
      textAlign: 'center',
      margin: '1%',
      fontSize: '12px',
      color: '#777',
      border: 'solid 1px white',
      '&:hover': {
        border: 'solid 1px #dcd3d3',
        cursor: 'pointer',
      },
    },
  },
  prev: {
    float: 'left',
    padding: '4px 10px',
    '&:hover': { cursor: 'pointer' },
    color: 'white',

    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '1.5rem',
    outline: 'none',
    '&:disabled': {
      color: '#b9b9b9',
      cursor: 'auto',
    },
  },
  next: {
    float: 'right',
    padding: '4px 10px',
    border: 'none',
    color: 'white',

    backgroundColor: 'transparent',
    '&:hover': { cursor: 'pointer' },
    fontSize: '1.5rem',
    outline: 'none',
    '&:disabled': {
      color: '#b9b9b9',
      cursor: 'auto',
    },
  },
  calendarDate: {
    textTransform: 'uppercase',
    color: 'black',
  },
}))

const DAYS = [
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
  'Søndag',
]

function Calendar({ data, onJoin, onLeave, onCreate, groups }) {
  const classes = useStyles()
  const currentDate = new Date()
  const [dateInfo, setDateInfo] = useState(getDateInfo(currentDate))
  const [dateJump, setDateJump] = useState(getDateJump(currentDate, 0))

  useEffect(() => {
    if (dateJump?.date) {
      setDateInfo(getDateInfo(dateJump?.date))
    }
  }, [dateJump])

  const days = new Array(dateInfo?.daysInMonth - 1)
    .fill(0)
    .map((el, index) => ({
      day: index,
      count: 0,
      schedules: [],
      date: new Date(dateJump.year, dateJump.month, index + 1, 11),
    }))
  data?.getSchedules?.schedules?.forEach((el) => {
    if (el.startAt > dateJump.start && el.startAt < dateJump.end) {
      const day = new Date(el.startAt).getDate()
      days[day - 1].count += 1
      days[day - 1].schedules.push(el)
    }
  })

  const weekDays = DAYS.map((el) => <li key={el}>{el.slice(0, 2)}</li>)
  const dayList = days.map((el, index) => (
    <Day
      data={el}
      index={index}
      groups={groups}
      onCreate={onCreate}
      onLeave={onLeave}
      onJoin={onJoin}
      key={'day-' + index}
    />
  ))

  const dateJumpNextHandler = () => {
    if (dateJump.month < currentDate.getMonth() + 3) {
      setDateJump(getDateJump(dateJump.date, +1))
    }
  }

  const dateJumPrevHandler = () => {
    if (dateJump.month > currentDate.getMonth() - 1) {
      setDateJump(getDateJump(dateJump.date, -1))
    }
  }
  return (
    <div className={classes.calendarContainer}>
      <div className={classes.calendarHeader}>
        <button
          className={classes.prev}
          onClick={dateJumPrevHandler}
          disabled={dateJump.month <= currentDate.getMonth()}
        >
          &#10094;
        </button>

        <span className={classes.calendarDate}>
          {dateInfo?.monthText}
          <br />
          {dateJump?.year}
        </span>
        <button
          className={classes.next}
          onClick={dateJumpNextHandler}
          disabled={dateJump.month >= currentDate.getMonth() + 3}
        >
          &#10095;
        </button>
      </div>
      <ul className={classes.weekdays}>{weekDays}</ul>
      <ul className={classes.days}>{dayList}</ul>
    </div>
  )
}

export default Calendar
