import gql from 'graphql-tag'

export const MY_SCHEDULES = gql`
  query mySchedules {
    mySchedules {
      count
      schedules {
        id
        title
        roomId
        createdAt
        updatedAt
        description
        topics
        language
        error
        creator {
          id
          firstName
          lastName
        }
        startAt
        members {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_SCHEDULES = gql`
  query getSchedules($from: String, $searchString: String, $groupId: ID) {
    getSchedules(from: $from, searchString: $searchString, groupId: $groupId) {
      count
      schedules {
        id
        title
        roomId
        createdAt
        description
        updatedAt
        topics
        language
        error
        creator {
          id
          firstName
          lastName
        }
        startAt
        members {
          id
          firstName
          lastName
        }
      }
    }
  }
`
export const CREATE_NEW_SCHEDULE = gql`
  mutation createNewSchedule($input: ScheduleInput) {
    createNewSchedule(input: $input) {
      id
      title
      roomId
      createdAt
      updatedAt
      description
      topics
      language
      error
      creator {
        id
        firstName
        lastName
      }
      startAt
      members {
        id
        firstName
        lastName
      }
    }
  }
`

export const JOIN_SCHEDULE = gql`
  mutation joinSchedule($scheduleId: ID!) {
    joinSchedule(scheduleId: $scheduleId) {
      id
      title
      roomId
      createdAt
      updatedAt
      topics
      description
      language
      error
      creator {
        id
        firstName
        lastName
      }
      startAt
      members {
        id
        firstName
        lastName
      }
    }
  }
`

export const LEAVE_SCHEDULE = gql`
  mutation leaveSchedule($scheduleId: ID!) {
    leaveSchedule(scheduleId: $scheduleId) {
      id
      title
      roomId
      createdAt
      updatedAt
      topics
      description
      language
      error
      creator {
        id
        firstName
        lastName
      }
      startAt
      members {
        id
        firstName
        lastName
      }
    }
  }
`
