import React, { useState, Fragment } from 'react'
import DaySchedules from './DaySchedules'
const COLOR = [
  // '#f8f8f8',
  '#f1f1f1',
  // '#efefef',
  '#e8e8e8',
  // '#e1e1e1',
  '#dfdfdf',
  // '#d8d8d8',
  '#d1d1d1',
]
function Day({ data, index, onCreate, groups }) {
  const [showSchedules, setShowSchedules] = useState(false)

  return (
    <Fragment>
      <li
        onClick={() => setShowSchedules(true)}
        style={{
          background:
            data.count > COLOR.length - 1
              ? COLOR[COLOR.length - 1]
              : COLOR[data.count],
        }}
      >
        {index + 1}
      </li>
      <DaySchedules
        open={showSchedules}
        groups={groups}
        setOpen={setShowSchedules}
        schedules={data?.schedules}
        date={data?.date}
        onCreate={onCreate}
      />
    </Fragment>
  )
}

export default Day
