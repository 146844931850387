import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuItem from '@material-ui/core/MenuItem'
const useStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  btn: {
    margin: '0 0.5rem',
    textDecoration: 'none',
    boxShadow: 'none',
    color: 'white',
    backgroundColor: '#e56d1b',
    '&:hover': {
      backgroundColor: '#ac5519',
      boxShadow: 'none',
    },
    border: 'none',
  },
  dropdown: {
    position: 'relative',
    zIndex: '1000',
  },
  dropdownContent: {
    position: 'absolute',
    backgroundColor: 'white',
    right: '10px',
  },
}))

function DesktopNav({ state, onLogin, onLogout, onSignup }) {
  const [innerMenu, setInnerMenu] = useState(false)
  const classes = useStyle()
  return (
    <nav className={classes.root}>
      {state?.user?.id ? (
        <Fragment>
          {innerMenu ? (
            <ClickAwayListener onClickAway={() => setInnerMenu(false)}>
              <div className={classes.dropdown}>
                {'Hei ' + state.user.firstName + ','}

                <IconButton onClick={() => setInnerMenu((prev) => !prev)}>
                  <AccountCircleIcon />
                </IconButton>
                <div className={classes.dropdownContent}>
                  <MenuItem onClick={onLogout}>Logg ut</MenuItem>
                </div>
              </div>
            </ClickAwayListener>
          ) : (
            <div className={classes.dropdown}>
              {'Hei ' + state.user.firstName + ','}
              <IconButton onClick={() => setInnerMenu((prev) => !prev)}>
                <AccountCircleIcon />
              </IconButton>
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={onSignup}
          >
            Ny profil
          </Button>
          <Button variant="contained" className={classes.btn} onClick={onLogin}>
            Logg inn
          </Button>
        </Fragment>
      )}
    </nav>
  )
}

export default DesktopNav
