import React, { useState, useEffect } from 'react'
import Calendar from './Calendar/Calendar'
import VerticalList from './VerticalList/VerticalList'
import HorizontalList from './HorizontalList/HorizontalList'
import GroupList from './GroupList/GroupList'
import useDebounce from '../../hooks/useDebounce'
import {
  MY_SCHEDULES,
  GET_SCHEDULES,
  CREATE_NEW_SCHEDULE,
  JOIN_SCHEDULE,
  LEAVE_SCHEDULE,
} from '../../queries/schedule'
import {
  MY_GROUPS,
  JOIN_GROUP,
  CREATE_NEW_GROUP,
  LEAVE_GROUP,
} from '../../queries/group'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  root: {
    // background: 'whitesmoke',
  },
  lastSection: {
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap-reverse',
    },
  },
}))

function Dashboard(props) {
  const [query, setQuery] = useState({
    searchString: '',
    from: new Date(),
    groupId: '',
  })
  const { debounceValue, loading: queryLoading } = useDebounce(query, 1000)

  useEffect(() => {
    refetchAll({
      searchString: query.searchString,
      from: query.from.toDateString(),
      groupId: query.groupId ? query.groupId : null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue])

  const classes = useStyles()
  const {
    data: userData,
    error: userError,
    loading: userLoading,
    refetch: refetchUser,
  } = useQuery(MY_SCHEDULES)
  const {
    data: allData,
    error: allError,
    loading: allLoading,
    refetch: refetchAll,
  } = useQuery(GET_SCHEDULES, {
    variables: { searchString: '', from: new Date().toString },
  })

  const { data: groupData, refetch: refetchGroups } = useQuery(MY_GROUPS, {
    fetchPolicy: 'network-only',
  })

  const [createSchedule] = useMutation(CREATE_NEW_SCHEDULE)
  const [joinSchedule] = useMutation(JOIN_SCHEDULE)
  const [leaveSchedule] = useMutation(LEAVE_SCHEDULE)
  const [joinGroup] = useMutation(JOIN_GROUP)
  const [createNewGroup] = useMutation(CREATE_NEW_GROUP)
  const [leaveGroup] = useMutation(LEAVE_GROUP)

  const handleJoinGroup = async (identifier) => {
    const res = await joinGroup({ variables: { identifier } })
    if (res.data.joinGroup.id) {
      refetchGroups({ fetchPolicy: 'network-only' })
    }
  }

  const handleCreateGroup = async (input) => {
    const res = await createNewGroup({ variables: { input } })
    if (res?.data?.createNewGroup?.id) {
      props.history.push('/groups/' + res?.data?.createNewGroup?.id)
    }
  }

  const handleLeaveGroup = async (groupId) => {
    const res = await leaveGroup({ variables: { groupId: groupId } })
    if (res?.data?.leaveGroup) {
      refetchGroups({ fetchPolicy: 'network-only' })
    }
  }

  const handlerCreateSchedule = async (input) => {
    await createSchedule({ variables: { input: input } })
    refetchAll({ fetchPolicy: 'network-only' })
    refetchUser({ fetchPolicy: 'network-only' })
  }
  const handlerJoinSchedule = async (scheduleId) => {
    await joinSchedule({ variables: { scheduleId } })
    refetchUser({ fetchPolicy: 'network-only' })
    refetchAll({ fetchPolicy: 'network-only' })
  }

  const handlerLeaveSchedule = async (scheduleId) => {
    await leaveSchedule({ variables: { scheduleId } })
    refetchAll({ fetchPolicy: 'network-only' })
    refetchUser({ fetchPolicy: 'network-only' })
  }
  return (
    <div className={classes.root}>
      <GroupList
        groups={groupData?.myGroups?.groups}
        setQuery={setQuery}
        onLeaveGroup={handleLeaveGroup}
      />
      <HorizontalList
        data={userData}
        error={userError}
        loading={userLoading}
        onLeave={handlerLeaveSchedule}
        onCreateSchedule={handlerCreateSchedule}
        onCreateGroup={handleCreateGroup}
        groups={groupData?.myGroups?.groups}
        onJoinGroup={handleJoinGroup}
        onLeaveGroup={handleLeaveGroup}
      />
      <section className={classes.lastSection}>
        <Calendar
          data={allData}
          error={userError}
          loading={userLoading}
          onCreate={handlerCreateSchedule}
          onJoin={handlerJoinSchedule}
          groups={groupData?.myGroups?.groups}
        />
        <VerticalList
          data={allData}
          error={allError}
          loading={allLoading || queryLoading}
          query={query}
          setQuery={setQuery}
          onLeave={handlerLeaveSchedule}
          onCreate={handlerCreateSchedule}
          onJoin={handlerJoinSchedule}
          groups={groupData?.myGroups?.groups}
        />
      </section>
    </div>
  )
}

export default Dashboard
