import gql from 'graphql-tag'

export const MY_GROUPS = gql`
  query myGroups {
    myGroups {
      count
      groups {
        id
        name
        owner {
          id
        }
      }
    }
  }
`

export const GET_GROUP_BY_ID = gql`
  query getGroupById($groupId: ID) {
    getGroupById(groupId: $groupId) {
      id
      name
      createdAt
      updatedAt
      open
      description
      identifier
      error
      schedules {
        id
        title
        roomId
        createdAt
        description
        updatedAt
        topics
        language
        error
        creator {
          id
          firstName
          lastName
        }
        startAt
        members {
          id
          firstName
          lastName
        }
      }
      owner {
        id
        firstName
        lastName
      }
      members {
        id
        firstName
        lastName
      }
      excluded {
        id
        firstName
        lastName
      }
    }
  }
`
export const CREATE_NEW_GROUP = gql`
  mutation createNewGroup($input: GroupInput) {
    createNewGroup(input: $input) {
      id
    }
  }
`

export const JOIN_GROUP = gql`
  mutation joinGroup($identifier: ID!) {
    joinGroup(identifier: $identifier) {
      id
    }
  }
`

export const LEAVE_GROUP = gql`
  mutation leaveGroup($groupId: ID!) {
    leaveGroup(groupId: $groupId)
  }
`

export const EXCLUDE_USER = gql`
  mutation excludeUser($groupId: ID!, $userId: ID!) {
    excludeUser(groupId: $groupId, userId: $userId) {
      id
      members {
        id
        firstName
        lastName
      }
      excluded {
        id
        firstName
        lastName
      }
    }
  }
`

export const RESET_USER = gql`
  mutation resetUserFromGroup($groupId: ID!, $userId: ID!) {
    resetUserFromGroup(groupId: $groupId, userId: $userId) {
      id
      members {
        id
        firstName
        lastName
      }
      excluded {
        id
        firstName
        lastName
      }
    }
  }
`
export const RESET_IDENTIFIER = gql`
  mutation resetGroupIdentifier($groupId: ID) {
    resetGroupIdentifier(groupId: $groupId) {
      id
      identifier
    }
  }
`

export const DELETE_GROUP = gql`
  mutation deleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId)
  }
`

export const UPDATE_GROUP = gql`
  mutation updateGroup($groupId: ID!, $input: GroupInput) {
    updateGroup(groupId: $groupId, input: $input) {
      id
      open
      name
      description
    }
  }
`
