import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}))
function Loader({ children, loading, error }) {
  const classes = useStyles()
  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    )
  }
  if (error) {
    return (
      <div className={classes.root}>
        <div>Error</div>
      </div>
    )
  }
  return <Fragment>{children}</Fragment>
}

export default Loader
