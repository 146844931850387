import React from 'react'
import { Switch } from 'react-router-dom'
import CustomeRoute from './components/CustomRoute'
import HomePage from './pages/Home/Home'
import Dashboard from './pages/Dashboard/Dashboard'
import Main from './layouts/Main/Main'
import VideoConf from './pages/VideoConf/VideoConf'
import Group from './pages/Group/Group'
function Routes() {
  return (
    <Switch>
      <CustomeRoute path="/" exact layout={Main} component={HomePage} />
      <CustomeRoute
        path="/dashboard"
        exact
        guarded
        layout={Main}
        component={Dashboard}
      />
      <CustomeRoute
        path="/video-conference/:roomid"
        exact
        guarded
        layout={Main}
        component={VideoConf}
      />{' '}
      <CustomeRoute
        path="/groups/:groupid"
        exact
        guarded
        layout={Main}
        component={Group}
      />
    </Switch>
  )
}

export default Routes
