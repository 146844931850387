import React, { Fragment, useContext } from 'react'
import { Context as AuthContext } from '../context/Auth'
import { useQuery } from '@apollo/react-hooks'
import { ME } from '../queries/user'
function UserInit() {
  const { initializeUser, logout } = useContext(AuthContext)
  useQuery(ME, {
    onCompleted: (data) => {
      if (data?.me?.id) {
        initializeUser({ user: { ...data.me } })
      } else {
        logout()
      }
    },
  })

  return <Fragment />
}

export default UserInit
