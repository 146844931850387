import { makeStyles } from '@material-ui/core/styles'
import Schedule from '../../components/Schedule'
import Loader from '../../components/Loader'
import Button from '@material-ui/core/Button'
import { useState } from 'react'
import AddForm from '../../components/AddForm'

const useStyles = makeStyles(() => ({
  root: {},
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    msOverflowY: 'auto',
    flexGrow: 1,
    padding: '5px 5px',
    boxSizing: 'border-box',
    margin: '5px 0',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 2rem',
  },

  empty: {
    width: '100%',
    textAlign: 'center',
  },
}))

function ScheduleList(props) {
  const classes = useStyles()
  const [showAddForm, setShowAddForm] = useState(false)

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>
        <span>Kommende møter</span>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setShowAddForm(true)}
        >
          Nytt Møte
        </Button>
        {props?.group?.id && (
          <AddForm
            open={showAddForm}
            setOpen={setShowAddForm}
            defaultGroup={props.group.id}
            groups={[props.group]}
            onCreate={props.onCreate}
          />
        )}
      </h2>
      <div className={classes.listContainer}>
        <Loader loading={props.loading} error={props.error}>
          {props?.group?.schedules
            ?.sort((a, b) => a.startAt - b.startAt)
            .map((el) => (
              <Schedule
                schedule={el}
                key={el.id}
                vertical={false}
                onJoin={props.onJoin}
                onLeave={props.onLeave}
              />
            ))}
          {props.group?.schedules?.length <= 0 && (
            <div className={classes.empty}>Ingen møter tilgjengelige</div>
          )}
        </Loader>
      </div>
    </div>
  )
}

export default ScheduleList
