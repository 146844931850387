import React, { useState } from 'react'
import ScheduleList from './ScheduleList'
import GroupForm from './GroupForm'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  GET_GROUP_BY_ID,
  RESET_IDENTIFIER,
  UPDATE_GROUP,
  EXCLUDE_USER,
  RESET_USER,
  DELETE_GROUP,
} from '../../queries/group'
import {
  CREATE_NEW_SCHEDULE,
  JOIN_SCHEDULE,
  LEAVE_SCHEDULE,
} from '../../queries/schedule'
const useStyles = makeStyles(() => ({
  root: {},
  main: {},
}))

function Group(props) {
  const [group, setGroup] = useState(null)
  const [createSchedule] = useMutation(CREATE_NEW_SCHEDULE)
  const [joinSchedule] = useMutation(JOIN_SCHEDULE)
  const [leaveSchedule] = useMutation(LEAVE_SCHEDULE)
  const [deleteGroup] = useMutation(DELETE_GROUP)

  useQuery(GET_GROUP_BY_ID, {
    variables: { groupId: props.match.params.groupid },
    onCompleted: (data) => {
      if (data?.getGroupById?.id) {
        setGroup(data.getGroupById)
      }
    },
  })
  const [resetIdentifier] = useMutation(RESET_IDENTIFIER, {
    variables: { groupId: props.match.params.groupid },
    onCompleted: (data) => {
      if (data?.resetGroupIdentifier?.id) {
        setGroup((prev) => ({ ...prev, ...data.resetGroupIdentifier }))
      }
    },
  })
  const [updateGroup] = useMutation(UPDATE_GROUP, {
    onCompleted: (data) => {
      if (data?.updateGroup?.id) {
        setGroup((prev) => ({ ...prev, ...data.updateGroup }))
      }
    },
  })
  const [excludeUser] = useMutation(EXCLUDE_USER, {
    onCompleted: (data) => {
      if (data?.excludeUser?.id) {
        setGroup((prev) => ({ ...prev, ...data.excludeUser }))
      }
    },
  })

  const [resetUser] = useMutation(RESET_USER, {
    onCompleted: (data) => {
      if (data?.resetUserFromGroup?.id) {
        setGroup((prev) => ({ ...prev, ...data.resetUserFromGroup }))
      }
    },
  })
  const classes = useStyles()

  const handleCreateSchedule = async (input) => {
    const res = await createSchedule({ variables: { input: input } })
    if (res?.data?.createNewSchedule?.id) {
      const schedules = group?.schedules || []
      const newSchedules = [...schedules, res.data.createNewSchedule]
      setGroup((prev) => ({ ...prev, schedules: newSchedules }))
    }
  }
  const handleJoinSchedule = async (scheduleId) => {
    const res = await joinSchedule({ variables: { scheduleId } })
    if (res?.data?.joinSchedule?.id) {
      const schedules = group?.schedules || []
      const newSchedules = schedules.filter((el) => el.id !== scheduleId)
      newSchedules.push(res?.data?.joinSchedule)
      setGroup((prev) => ({ ...prev, schedules: newSchedules }))
    }
  }

  const handleLeaveSchedule = async (scheduleId) => {
    const res = await leaveSchedule({ variables: { scheduleId } })
    if (res?.data?.leaveSchedule?.id) {
      const schedules = group?.schedules || []
      const newSchedules = schedules.filter((el) => el.id !== scheduleId)
      newSchedules.push(res?.data?.leaveSchedule)
      setGroup((prev) => ({ ...prev, schedules: newSchedules }))
    }
  }
  const handleDelete = async (groupId) => {
    const res = await deleteGroup({ variables: { groupId } })
    if (res?.data?.deleteGroup) {
      props.history.push('/dashboard')
    }
  }

  return (
    <div className={classes.root}>
      <GroupForm
        group={group}
        onReset={resetIdentifier}
        onUpdate={updateGroup}
        onExclude={excludeUser}
        onResetUser={resetUser}
        onDelete={handleDelete}
      />
      <ScheduleList
        group={group}
        onJoin={handleJoinSchedule}
        onCreate={handleCreateSchedule}
        onLeave={handleLeaveSchedule}
      />
    </div>
  )
}

export default Group
