import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation logIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      user {
        id
        firstName
        lastName
        createdAt
        updatedAt
      }
      token
      error
    }
  }
`

export const SIGNUP = gql`
  mutation signUp($input: SignUpInput) {
    signUp(input: $input) {
      user {
        id
        firstName
        lastName
        createdAt
        updatedAt
      }
      token
      error
    }
  }
`

export const ME = gql`
  query me {
    me {
      id
      error
      firstName
      email
      lastName
      createdAt
      updatedAt
    }
  }
`
