import { useState, useEffect } from 'react'

function useDebounce(value, delay) {
  const [debounceValue, setDebounceValue] = useState(value)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    const timer = setTimeout(() => {
      setDebounceValue(value)
      setLoading(false)
    }, delay)
    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])
  return { debounceValue, loading }
}

export default useDebounce
