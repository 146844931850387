import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Context as UserContext } from '../context/Auth'

function CustomRoute(props) {
  const { component: Component, layout: Layout, guarded, ...rest } = props
  const { state } = useContext(UserContext)
  if (props.path === '/') {
    if (state?.token) {
      return <Redirect to="/dashboard" />
    }
  }
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        guarded ? (
          state?.token ? (
            <Layout {...routeProps}>
              <Component {...routeProps} />
            </Layout>
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        )
      }
    />
  )
}

export default CustomRoute
