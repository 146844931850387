import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { KeyboardDateTimePicker } from '@material-ui/pickers'
const useStyles = makeStyles((theme) => ({
  listHeader: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0.5rem 1rem 1rem 1rem',
  },
  headerTitle: {
    textAlign: 'center',
    margin: 0,
  },
  formControl: {
    marginTop: '10px',
  },
}))

export default function VListHeader({ query, setQuery, groups }) {
  const classes = useStyles()
  const handleChange = (e) => {
    if (e instanceof Date) {
      setQuery((prev) => ({ ...prev, from: e }))
    } else if (e?.target?.name === 'searchString') {
      setQuery((prev) => ({ ...prev, searchString: e?.target?.value }))
    } else if (e?.target?.name === 'groupId') {
      setQuery((prev) => ({ ...prev, groupId: e?.target?.value }))
    }
  }

  return (
    <Paper className={classes.listHeader} elevation={1}>
      <h4 className={classes.headerTitle}>Finn ditt møte</h4>
      <Grid container justify="space-around">
        <KeyboardDateTimePicker
          margin="normal"
          fullWidth
          inputVariant="outlined"
          size="small"
          id="date-picker-dialog"
          label="Velg tidspunkt"
          minDate={new Date()}
          maxDate={new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000)}
          name="from"
          format="dd.MM.yyyy hh:mm a"
          cancelLabel="Avbryt"
          value={query.from}
          onChange={handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <Grid container justify="space-around">
        <TextField
          fullWidth
          autoComplete="off"
          variant="outlined"
          size="small"
          autoFocus={true}
          name="searchString"
          label="Søk"
          value={query.searchString}
          onChange={handleChange}
        />
      </Grid>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        size="small"
        fullWidth
      >
        <InputLabel id="demo-simple-select-outlined-label">Gruppe</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          name="groupId"
          value={query.groupId}
          onChange={handleChange}
          label="Gruppe"
        >
          <MenuItem value="">
            <em>Åpen møter</em>
          </MenuItem>
          {groups?.map((el) => (
            <MenuItem value={el.id} key={el.id}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  )
}
