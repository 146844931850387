import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  home: {
    minHeight: '80vh',
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
  },
  box1: {
    width: '48%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '80vh',
    minWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '50%',
      minHeight: '400px',
    },
  },
  headerText: {
    padding: '0 3rem',
    [theme.breakpoints.down('sm')]: { fontSize: '2.5rem' },
  },
  bodyText: {
    padding: '2rem 3rem',
    fontSize: 'x-large',
    [theme.breakpoints.down('sm')]: { fontSize: 'large' },
  },
  box2: {
    width: '48%',
    position: 'relative',
    overflow: 'hidden',
    minWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '50%',
      minHeight: '400px',
    },
  },
  boxCenter: {
    width: '100%',
    paddingTop: '100%',
    position: 'absolute',
    top: '50%',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  boxContainer: {
    position: 'absolute',
    top: '50%',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    maxHeight: '400px',
    maxWidth: '400px',
  },

  grayBox: {
    width: '45%',
    height: '45%',
    margin: '2%',
    backgroundColor: 'lightgray',
  },
}))

function Home() {
  const classes = useStyles()
  return (
    <div className={classes.home}>
      <div className={classes.box1}>
        <Typography variant="h2" className={classes.headerText}>
          Enkelt, åpen og verdifull
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          Wemeet åpner opp muligheter for deg til å møte andre personligheter og
          kompetanser med mål å styrke enkelte sosiale og faglige ferdigheter.{' '}
        </Typography>
      </div>
      <div className={classes.box2}>
        <div className={classes.boxCenter}>
          <div className={classes.boxContainer}>
            <div className={classes.grayBox} />
            <div className={classes.grayBox} />
            <div className={classes.grayBox} />
            <div className={classes.grayBox} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
