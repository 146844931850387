import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'
const useStyle = makeStyles({
  header: {
    height: '70px',
    // backgroundColor: "green",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem',
  },
  logo: {
    fontSize: 'xx-large',
    fontWeight: 'bolder',
    color: '#03506f',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

function Header({ onSignup, onLogin, onLogout, state }) {
  const classes = useStyle()
  return (
    <header className={classes.header}>
      <NavLink className={classes.logo} to="/">
        Wemeet
      </NavLink>
      <DesktopNav
        state={state}
        onSignup={onSignup}
        onLogin={onLogin}
        onLogout={onLogout}
      />
      <MobileNav
        state={state}
        onSignup={onSignup}
        onLogin={onLogin}
        onLogout={onLogout}
      />
    </header>
  )
}

export default Header
