import React from 'react'

function VideoConf(props) {
  const jitsiContainerId = 'jitsi-container-id'
  const [jitsi, setJitsi] = React.useState({})

  const loadJitsiScript = () =>
    new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = 'https://meet.jit.si/external_api.js'
      script.async = true
      script.onload = resolve
      document.body.appendChild(script)
    })
  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript()
    }
    const domain = 'meet.jit.si'
    const options = {
      roomName: props.match.params.roomid,
      // height: 400,
      parentNode: document.getElementById(jitsiContainerId),
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        SHOW_JITSI_WATERMARK: false,
      },
      configOverwrite: {
        disableSimulcast: false,
      },
    }

    const _jitsi = new window.JitsiMeetExternalAPI(domain, options)

    setJitsi(_jitsi)
  }

  React.useEffect(() => {
    initialiseJitsi()

    return () => jitsi?.dispose?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <div id={jitsiContainerId} style={{ height: 720, width: '100%' }} />
}

export default VideoConf
