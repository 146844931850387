import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { Provider as AuthProvider } from './context/Auth'
import client from './apollo'
import UserInit from './components/UserInit'
import nbLocal from 'date-fns/locale/nb'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import './App.css'

const token = window.localStorage.getItem('token')
function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nbLocal}>
          {token && <UserInit />}
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
