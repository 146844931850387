import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PwdField from './PwdField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
  },
  form: {
    backgroundColor: 'white',
    padding: '1rem',
    position: 'relative',
    borderRadius: '5px',
    margin: '0.5rem',
    maxWidth: '450px',
  },
  input: { marginTop: '1.5rem' },
  submit: {
    backgroundColor: '#fc7e28',
    marginTop: '1rem',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e56d1b',
    },
  },
  cancel: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
    fontSize: 'xx-large',
  },
  helpText: {
    paddingLeft: '0.1rem',
  },
  checkbox: {
    marginTop: '1rem',
  },
}))

const defaultUserInput = {
  email: '',
  password: '',
  passwordRepeat: '',
  firstName: '',
  lastName: '',
  termAccepted: false,
}

function Signup(props) {
  const classes = useStyles()
  const [userInput, setUserInput] = useState(defaultUserInput)
  const [helpText, setHelpText] = useState('')
  const [showPwd, setShowPwd] = useState(false)

  const inputHandler = (e) => {
    setUserInput((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const checkboxHandler = (e) => {
    setUserInput((prev) => ({ ...prev, [e.target.name]: e.target.checked }))
  }

  const onCloseHandler = () => {
    setUserInput(defaultUserInput)
    props.onClose()
  }

  const submitHandler = async (e) => {
    setHelpText(null)
    e.preventDefault()
    if (userInput.password !== userInput.passwordRepeat) {
      setHelpText('Passordene er ikke like')
    } else if (!userInput.termAccepted) {
      setHelpText('Vilkårene er ikke akseptert')
    } else {
      const { passwordRepeat, ...rest } = userInput
      const res = await props.onSubmit(rest)
      if (res) {
        setHelpText(res)
      }
    }
  }
  useEffect(() => {
    return () => {
      setUserInput(defaultUserInput)
      setHelpText(null)
    }
  }, [])

  const invalidInputHandler = (e) => {
    switch (e.target.name) {
      case 'firstName':
        e.target.setCustomValidity('Vennligst fyll ut dette feltet')
        break
      case 'lastName':
        e.target.setCustomValidity('Vennligst fyll ut dette feltet')
        break
      case 'email':
        e.target.setCustomValidity('Ugyldig epostadresse')
        break
      case 'password':
        e.target.setCustomValidity(
          'Passordet må inneholde minst ett nummer og en stor og liten bokstav og minst 6 eller flere tegn'
        )
        break
      case 'passwordRepeat':
        e.target.setCustomValidity(
          'Passordet må inneholde minst ett nummer og en stor og liten bokstav og minst 6 eller flere tegn'
        )
        break

      default:
        break
    }
  }
  const onInputHandler = (e) => {
    e.target.setCustomValidity('')
  }

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={onCloseHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <form className={classes.form} onSubmit={submitHandler}>
          <Typography variant="h4" align="center">
            Ny Profil
          </Typography>
          <Button
            title="close modal"
            className={classes.cancel}
            onClick={onCloseHandler}
          >
            ⨉
          </Button>
          <div className={classes.inputContainer}>
            <TextField
              label="Fornavn"
              name="firstName"
              onInvalid={invalidInputHandler}
              onInput={onInputHandler}
              type="text"
              variant="outlined"
              required
              fullWidth
              value={userInput.firstName}
              onChange={inputHandler}
              className={classes.input}
            />
            <TextField
              label="Etternavn"
              name="lastName"
              onInvalid={invalidInputHandler}
              onInput={onInputHandler}
              type="text"
              variant="outlined"
              required
              fullWidth
              value={userInput.lastName}
              onChange={inputHandler}
              className={classes.input}
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              onInvalid={invalidInputHandler}
              onInput={onInputHandler}
              required
              fullWidth
              value={userInput.email}
              onChange={inputHandler}
              className={classes.input}
            />
            <PwdField
              label="Passord"
              name="password"
              onInvalid={invalidInputHandler}
              onInput={onInputHandler}
              value={userInput.password}
              onChange={inputHandler}
              className={classes.input}
              setShowPwd={() => setShowPwd((prev) => !prev)}
              showPwd={showPwd}
              labelWidth={65}
            />
            <PwdField
              label="Bekreft passord"
              name="passwordRepeat"
              onInvalid={invalidInputHandler}
              onInput={onInputHandler}
              value={userInput.passwordRepeat}
              onChange={inputHandler}
              className={classes.input}
              setShowPwd={() => setShowPwd((prev) => !prev)}
              showPwd={showPwd}
              labelWidth={120}
            />
            {helpText && (
              <Typography
                variant="body2"
                color="error"
                className={classes.helpText}
              >
                {helpText}
              </Typography>
            )}
          </div>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                color="primary"
                name="termAccepted"
                onChange={checkboxHandler}
                checked={userInput.termAccepted}
              />
            }
            label="Jeg godtar vilkår og personvern til weemet."
          />
          <Button
            variant="contained"
            fullWidth
            className={classes.submit}
            type="submit"
          >
            Logg inn
          </Button>
        </form>
      </Fade>
    </Modal>
  )
}

export default Signup
