import { makeStyles } from '@material-ui/core/styles'
import Schedule from '../../../components/Schedule'
import VListHeader from './VListHeader'
import Loader from '../../../components/Loader'

const useStyles = makeStyles(() => ({
  verticalList: {
    width: '33%',
    boxSizing: 'border-box',
    flexGrow: 1,
    padding: '0 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    maxHeight: '500px',
    marginBottom: '6rem',
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '370px',
    overflowY: 'auto',
    msOverflowY: 'auto',
    flexGrow: 1,
    padding: '5px 5px',
    boxSizing: 'border-box',
    margin: '5px 0',
  },

  empty: {
    width: '100%',
    textAlign: 'center',
  },
}))

function VerticalList(props) {
  const classes = useStyles()
  return (
    <div className={classes.verticalList}>
      <VListHeader
        refetch={props.refetch}
        query={props.query}
        setQuery={props.setQuery}
        groups={props.groups}
      />
      <div className={classes.listContainer}>
        <Loader loading={props.loading} error={props.error}>
          {props.data?.getSchedules?.schedules
            ?.sort((a, b) => a.startAt - b.startAt)
            .map((el) => (
              <Schedule
                schedule={el}
                key={el.id}
                vertical={true}
                onJoin={props.onJoin}
                onLeave={props.onLeave}
              />
            ))}
          {props.data?.getSchedules?.schedules?.length <= 0 && (
            <div className={classes.empty}>Ingen møter tilgjengelige</div>
          )}
        </Loader>
      </div>
    </div>
  )
}

export default VerticalList
