import React, { useState, useContext } from 'react'
import Header from './Header'
import Footer from './Footer'
import { makeStyles } from '@material-ui/core/styles'
import Login from './Login'
import Signup from './Signup'
import { SIGNUP, LOGIN } from '../../queries/user'
import { useMutation } from '@apollo/react-hooks'
import { Context as UserContext } from '../../context/Auth'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '100%',
    minHeight: '100vh',
    paddingBottom: '30px',
    boxSizing: 'border-box',
  },
})
function Main({ children, history }) {
  const classes = useStyles()
  const [onLogin, setOnLogin] = useState(false)
  const [onSignup, setOnSignup] = useState(false)

  const { login, logout, state } = useContext(UserContext)
  const [signUp] = useMutation(SIGNUP)
  const [logIn] = useMutation(LOGIN)

  const loginHandler = async (input) => {
    try {
      const res = await logIn({ variables: { ...input } })
      if (res.data?.logIn?.token) {
        setOnLogin(false)
        login({ ...res.data.logIn })
        history.push('/dashboard')
        return false
      } else if (res.data?.logIn?.error) {
        return res.data.logIn.error
      } else {
        return 'Feil epost eller passord'
      }
    } catch (error) {
      return 'Feil epost eller passord'
    }
  }
  const signupHandler = async (input) => {
    try {
      const res = await signUp({ variables: { input } })
      if (res.data?.signUp?.token) {
        setOnSignup(false)
        login({ ...res.data.signUp })
        history.push('/dashboard')
        return false
      } else if (res.data?.signUp?.error) {
        return res.data.signUp.error
      } else {
        return 'Registering ble ikke gjennomført'
      }
    } catch (error) {
      return 'Registering ble ikke gjennomført'
    }
  }

  return (
    <div className={classes.root}>
      <Header
        onLogin={() => setOnLogin(true)}
        onSignup={() => setOnSignup(true)}
        onLogout={logout}
        state={state}
      />
      {children}
      <Footer />
      <Login
        open={onLogin}
        onClose={() => setOnLogin(false)}
        onSubmit={loginHandler}
      />
      <Signup
        open={onSignup}
        onClose={() => setOnSignup(false)}
        onSubmit={signupHandler}
      />
    </div>
  )
}

export default Main
